const brand = [
  { name: "Alothailand", code: "ALO" },
  { name: "Banilaco", code: "BC" },
  { name: "Bodyshop", code: "BS" },
  { name: "CentralBeauty", code: "CB" },
  { name: "Clarins", code: "CR" },
  { name: "DcashPro", code: "DCASH" },
  { name: "GmHealthCheck", code: "GHC" },
  { name: "Hourglass", code: "HG" },
  { name: "Huapla", code: "HUAP" },
  { name: "Jewellista", code: "JWL" },
  { name: "KcInterFoods", code: "KCIF" },
  { name: "Kis", code: "KIS" },
  { name: "Lolane", code: "LOLANE" },
  { name: "Mille", code: "ML" },
  { name: "Nissin", code: "NS" },
  { name: "Samyanmitrtown", code: "SYM" },
  { name: "Seasun", code: "SEASUN" },
  { name: "Silomedge", code: "SE" },
  { name: "Snakebrandfamily", code: "SNB" },
  { name: "Thaniyagroup", code: "TG" },
  { name: "Yongcharoen", code: "YCR" },
  { name: "YugenThailand", code: "YGT" },
  { name: "Fatcat", code: "FC" },
  { name: "BrootThailand", code: "BT" },
  { name: "bdmswellness", code: "BDMS" },
  { name: "BeautyBuffet", code: "BB" },
  { name: "doikham", code: "DK" },
  { name: "innepetfood", code: "INPF" },
  { name: "ipst", code: "IPST" },
  { name: "smeg", code: "SMEG" },
  { name: "thewhoo", code: "TW" },
  { name: "COS", code: "COS" },
  { name: "COOrlebarbrown", code: "OB" },
  { name: "skinprorx", code: "SP" },
];

const getBrandCode = () => {
  let host = window.location.hostname;
  let b = host.split("-")[0];
  let codeIndex = brand.find((el) => el.name.toLowerCase() == b);
  if (codeIndex) return codeIndex.code;
  return "DEV";
};
export { getBrandCode };
